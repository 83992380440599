import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Modal, Button } from "react-bootstrap";
import DriveModal from "./driveModal";
import moment from "moment";

const InputFile2 = ({ plf, setInputFile2Out, selectedRootWorkflow }) => {
  const [showInputsModal, setShowInputsModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [inputFileOut, setInputFileOut] = useState([]);



  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    if (Object.keys(rowSelection).length === 0) return;
    let selectedRow = Object.keys(rowSelection);
    selectedRow = parseInt(selectedRow[0]);

    let x = plf[selectedRow];
    if (!x) return;
    setInputFile2Out(x)
    console.log(x); 
  }, [rowSelection]);


  
  return (
    <>
      <div
        // className="text-block-42"
        className="btn-1 type-2"
        style={{
          width: "200px",
          padding: "0px 20px",
          marginBottom: "30px",
          marginTop: "15px",
          minHeight: "45px",
        }}
        onClick={() => setShowInputsModal(true)}
      >
        <div className="btn-1-text">Add Input Files</div>
      </div>
      <DriveModal
        show={showInputsModal}
        handleClose={() => setShowInputsModal(false)}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      {/* <div className="right-support-col">
        edd
      </div> */}
    </>
  );
};

export default InputFile2;
